@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html{
  scroll-behavior: smooth;
}

body {
  background: linear-gradient(90deg, #FFF9F9 0%, #EBE9FF 100%);
  font-family: "Poppins", serif;
  font-weight: 400;
  font-style: normal;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.active {
  background: #7657f6;
  color: #fff;
}



.loader2 {
  display: flex;
  justify-content: space-between;
  width: 60px;
}

.loader2 div {
  width: 15px;
  height: 15px;
  background-color: #7657f6;
  border-radius: 50%;
  animation: bounce 1.5s infinite ease-in-out;
  box-shadow: 0 0 15px rgba(52, 152, 219, 0.6);
}

.loader2 div:nth-child(2) {
  animation-delay: -0.3s;
}

.loader2 div:nth-child(3) {
  animation-delay: -0.6s;
}

@keyframes bounce {
  0%, 80%, 100% { transform: scale(0); }
  40% { transform: scale(1); }
}
